<template>
  <div class="card-no-style align-right">
    <p class="card-margin-bottom-small text-light">Lust auf mehr?</p>
    <RouterLink class="button button-light icon-button" to="/gallery">360° Rundgang des Solarzentrum <ArrowRight/></RouterLink>
  </div>
</template>
<script>
import ArrowRight from '../../SVG/ArrowRight.svg';


export default {
  name : "GalleryLink",
  components : {
    ArrowRight
  }
}
</script>