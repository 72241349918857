<template>
  <div class="component checkbox" :class="{'disabled': disabled}">
    <input :disabled="disabled" :id="id + '_checkbox'" type="checkbox" :checked="checked" @input="onChange"/>
    <label class="checkbox-label" :for="id + '_checkbox' ">{{title}}</label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props:{
    title :{
      type : String,
      default : ""
    },
    checked :{
      type : Boolean,
      default: false,
      required : true
    },
    disabled:{
      type : Boolean,
      default :false,
      required : false
    }

  },
  data: function(){
    return {
      id : null

    }
  },
  mounted(){
    this.id = this._uid;
  }, 
  methods: {
    onChange: function(e){
      this.$emit("onChange", e.target.checked);
    }
  }
}
</script>

<style lang="scss" scoped>
.component.checkbox{
  margin-bottom: .5rem;
  height: 24px;
}

input[type="checkbox"]{
  display: none;
  position: absolute;
}

.checkbox-label{
  display: block;
  position: relative;
  padding-left:50px;
  cursor: pointer;

  .disabled &{
    color : #ccc;
    cursor: not-allowed;
    &:before,&:after{
      border:2px solid #ccc;
    }
  }

  &:before, &:after{
    content: "";
    position: absolute;
    
    display:block;
    
    border:2px solid #9A9A9A;
    border-radius:20px;
    background: #E5E9EB;
  }

  &:before{
    top:0;
    left:0;
    height:20px;
    width:40px;
    box-shadow: inset 2px 2px 3px 0 rgba(0,0,0,.2);
  }

  &:after{
    top:-2px;
    left:-2px;
    height:24px;
    width:24px;
    background:#E5E9EB;
    transition-duration: .2s;
  }
}

input[type="checkbox"]:checked + label{
  &:before{
    background: $light-orange;
    border:none;
    height: 24px;
    width: 44px;
  }
  
  &:after{
    left:20px;
    background: $orange;
    border:2px solid #fff;
  }
}

.disabled input[type="checkbox"]:checked + label{
  &:before{
    background: $dark-grey!important;
  }
  
  &:after{
    background: $dark-grey!important;
  }
}

</style>