<template>
  <div id="debug">
    <!-- <button @click="SaveCameraPosition">Save Camera</button> -->
  </div>
</template>
<script>
import axios from "axios";
// ES6:
import * as dat from "dat.gui";
import Utility from '../utils';

import {NoToneMapping ,LinearToneMapping, ReinhardToneMapping, CineonToneMapping, ACESFilmicToneMapping, Vector3} from 'three';
const toneMappingOptions = {
  "NoToneMapping" : NoToneMapping ,
  "LinearToneMapping" : LinearToneMapping, 
  "ReinhardToneMapping" : ReinhardToneMapping, 
  "CineonToneMapping" : CineonToneMapping, 
  "ACESFilmicToneMapping" : ACESFilmicToneMapping
}


import DebugLightMixin from './debug_light_mixin';
import DebugCameraMixin from './debug_camera_mixin';
import DebugPostMixin from './debug_post_mixin';

import mainConfig from '../../main.config';

export default {
  name: "Debug",
  mixins : [DebugLightMixin,DebugCameraMixin, DebugPostMixin],
  data() {
    return {
      gui: null,
      folders: {},
      settings: {
        SaveCameraPosition: this.SaveCameraPosition,
        SaveLightSettings:  this.SaveLightSettings,
        SavePostSettings:  this.SavePostSettings,
        SaveScreenshot : this.SaveScreenshot,
        SetVRCamera : ()=>{ 
          this.$store.state.world.mainScene.xr.Controls.SetPosition(0,3,22);
          this.$store.state.world.mainScene.xr.Controls.SetTarget(0,0,0);
        },
        GetVRPosition : ()=>{ 
           var worldPos = new Vector3();
          this.$store.state.world.mainScene.xr.SceneController.sceneTarget[this.$store.state.world.mainScene.xr.Scene.name].getWorldPosition(worldPos);

          var rot = this.$store.state.world.mainScene.xr.SceneController.sceneTarget[this.$store.state.world.mainScene.xr.Scene.name].parent.rotation;
          
          var VROption = {
            VR_Position : this.$store.state.world.mainScene.xr.SceneController.sceneGroups[this.$store.state.world.mainScene.xr.Scene.name].position,
            VR_Scale : this.$store.state.world.mainScene.xr.SceneController.sceneGroups[this.$store.state.world.mainScene.xr.Scene.name].scale,
            VR_Target : rot
          }

          this.SaveVRSettings(VROption.VR_Position, VROption.VR_Target, VROption.VR_Scale);
          console.table(VROption);
        },
        Spring: () => this.$store.commit("area/SetCurrentSeason", "Spring"),
        Summer: () => this.$store.commit("area/SetCurrentSeason", "Summer"),
        Autumn: () => this.$store.commit("area/SetCurrentSeason", "Autumn"),
        Winter: () => this.$store.commit("area/SetCurrentSeason", "Winter"),
        SunPosition: this.$store.state.area.sunPosition,
        SunAngle: 0.1,
        
        toneMappingExposure : .6,
        toneMapping : "LinearToneMapping",

        FOV_focus: 37,
        FOV_aperture: 0,
        FOV_maxblur: 0.004,
        lut: "Optima",

        sunsetCenter : 0.5,

        day_SunIntensity: 0.5,
        day_SunColor: [255, 255, 255],
        day_AmbientIntensity: 0.5,
        day_AmbientColor: [255, 255, 255],
        day_HemiIntensity: 0.5,
        day_HemiLightSkyColor: [255, 255, 255],
        day_HemiLightFloorColor: [255, 255, 255],
        day_sky_color_bottom: "#eeeeee",
        day_sky_color_center: "#eeeeee",
        day_sky_color_top: "#eeeeee",
        
        sunset_SunIntensity: 0.5,
        sunset_SunColor: [255, 255, 255],
        sunset_AmbientIntensity: 0.5,
        sunset_AmbientColor: [255, 255, 255],
        sunset_HemiIntensity: 0.5,
        sunset_HemiLightSkyColor: [255, 255, 255],
        sunset_HemiLightFloorColor: [255, 255, 255],
        sunset_sky_color_bottom: "#eeeeee",
        sunset_sky_color_center: "#eeeeee",
        sunset_sky_color_top: "#eeeeee",

        night_SunIntensity: 0.5,
        night_SunColor: [255, 255, 255],
        night_AmbientIntensity: 0.5,
        night_AmbientColor: [255, 255, 255],
        night_HemiIntensity: 0.5,
        night_HemiLightSkyColor: [255, 255, 255],
        night_HemiLightFloorColor: [255, 255, 255],
        night_sky_color_bottom: "#eeeeee",
        night_sky_color_center: "#eeeeee",
        night_sky_color_top: "#eeeeee",
      },
    };
  },
  mounted() {

    if(mainConfig.development){
      this.Init();
    }


    // this.$store.watch(state => state.content.currentViewContent, (val)=>{
    //   this.UpdateLightSettings(val);
    //   this.UpdatePostSettings(val);
    // });
    // this.$store.watch(state => state.area.season,b => this.UpdateLightSettings(this.$store.state.content.currentViewContent));

  },
  
  methods: {
    
    Init() {
      this.gui = new dat.GUI();
      this.gui.close();
      this.folders.seasons = this.gui.addFolder("Seasons");
      // this.folders.seasons.add(this.settings, "Spring" , )

      this.folders.seasons.add(this.settings, "Spring");
      this.folders.seasons.add(this.settings, "Summer");
      this.folders.seasons.add(this.settings, "Autumn");
      this.folders.seasons.add(this.settings, "Winter");

      this.folders.scene = this.gui.addFolder("Scene Settings");
      this.folders.scene
        .add(this.settings, "SunPosition", 0, 1)
        .onChange((newValue) => {
          this.$store.commit("area/SetSunPosition", newValue);
          var newPageObj = {};
          Object.assign(newPageObj, this.$store.state.content.currentViewContent);
          newPageObj.SunPosition = newValue;
          this.$store.commit("content/SetCurrentViewContent", newPageObj);
        });

      this.folders.scene
        .add(this.settings, "SunAngle", 0, 3)
        .onChange((newValue) => {

          this.settings.SunAngle = newValue;

          var newPageObj = Object.assign({}, this.$store.state.content.currentViewContent);
              newPageObj.SunAngle = newValue;

          this.$store.state.world.mainScene.UpdateShadows();
        
          this.$store.commit("content/SetCurrentViewContent", newPageObj);
        });

      this.folders.scene.add(this.settings, "sunsetCenter", 0, 1).onChange(
        (newValue) => {
          this.$store.state.world.mainScene.rooms.House.Sun.sunsetCenter = newValue;
          
        }
      );
      


      this.folders.cms = this.gui.addFolder("Update CMS data");
      this.folders.cms
        .add(this.settings, "SaveCameraPosition")
        .name("Save Camera Position");
      this.folders.cms
        .add(this.settings, "SaveLightSettings")
        .name("Save Light Settings");
      this.folders.cms
        .add(this.settings, "SavePostSettings")
        .name("Save Post Settings");

      this.folders.Renderer = this.gui.addFolder("Renderer");
      
      this.folders.Renderer.add(this.settings, "toneMappingExposure", 0, 10).onChange(
        (newValue) => {
          //this.ChangePostSetting("gamma" , newValue);
          this.$store.state.world.mainScene.xr.Renderer.instance.toneMappingExposure = newValue;
        }
      );

  /// SAVE SCREENSHOTS
      this.folders.screenshot = this.gui.addFolder("Screenshots");
      this.folders.screenshot
        .add(this.settings, "SaveScreenshot")
        .name("Save Screenshots");



      
      
      this.folders.Renderer.add(this.settings, "toneMapping",  ["NoToneMapping" ,"LinearToneMapping", "ReinhardToneMapping", "CineonToneMapping", "ACESFilmicToneMapping"]).onChange(
        (newValue) => {
          //this.ChangePostSetting("gamma" , newValue);

          this.$store.state.world.mainScene.xr.Renderer.instance.toneMapping =  toneMappingOptions[newValue];


          this.$store.state.world.mainScene.xr.Scene.traverse((obj)=>{ if(obj.type == "Mesh" ){ obj.material.needsUpdate = true; } })
        }
      );

      

      this.folders.FOV = this.gui.addFolder("FOV");

      this.folders.FOV.add(this.settings, "FOV_aperture", 0, 0.009).onChange(
        (newValue) => {
          this.ChangePostSetting("FOV_aperture" , newValue);
          this.$store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.aperture.value = newValue;
        }
      );
      this.folders.FOV.add(this.settings, "FOV_focus", 0, 50).onChange(
        (newValue) => {
          this.ChangePostSetting("FOV_focus" , newValue);
          this.$store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.focus.value = newValue;
        }
      );
      this.folders.FOV.add(this.settings, "FOV_maxblur", 0, 0.05).onChange(
        (newValue) => {

          this.ChangePostSetting("FOV_maxblur" , newValue);
          this.$store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value = newValue;
        }
      );

/** Day LightSettings */

    this.folders.LightDay = this.gui.addFolder("Day - Light Settings");
      this.folders.LightDay.add( this.settings, "day_SunIntensity", 0, 10 ).onChange((newValue) => {
        this.ChangeLight("SunIntensity", "DayLightSetting" , newValue);        
      });

      this.folders.LightDay.addColor(this.settings, "day_SunColor").onChange( (newColor) => {
        this.ChangeLight("SunColor","DayLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add(this.settings, "day_AmbientIntensity", 0, 5 ).onChange((newValue) => {
        this.ChangeLight("AmbientIntensity", "DayLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "day_AmbientColor").onChange((newColor) => {
        this.ChangeLight("AmbientColor","DayLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add( this.settings,"day_HemiIntensity",0,5).onChange((newValue) => {
        this.ChangeLight("HemiIntensity", "DayLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "day_HemiLightSkyColor").onChange((newColor) => {
        this.ChangeLight("HemiLightSkyColor","DayLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        })); 
      });

      this.folders.LightDay.addColor(this.settings, "day_HemiLightFloorColor").onChange((newColor) => {
        this.ChangeLight("HemiLightFloorColor","DayLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });
      

      this.folders.DaySky = this.gui.addFolder("Day - Sky");
      this.folders.DaySky.addColor(this.settings, "day_sky_color_top").onChange((newColor) => {
        console.log("color " , newColor);
        this.ChangeLight("sky_color_top","DayLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      this.folders.DaySky.addColor(this.settings, "day_sky_color_center").onChange((newColor) => {
        this.ChangeLight("sky_color_center","DayLightSetting" ,Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      
      this.folders.DaySky.addColor(this.settings, "day_sky_color_bottom").onChange((newColor) => {
        this.ChangeLight("sky_color_bottom","DayLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      
      
/** Sunset LightSettings */
    this.folders.LightDay = this.gui.addFolder("Sunset - Light Settings");
      this.folders.LightDay.add( this.settings, "sunset_SunIntensity", 0, 5 ).onChange((newValue) => {
        this.ChangeLight("SunIntensity", "SunsetLightSetting" , newValue);        
      });

      this.folders.LightDay.addColor(this.settings, "sunset_SunColor").onChange( (newColor) => {
        this.ChangeLight("SunColor","SunsetLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add(this.settings, "sunset_AmbientIntensity", 0, 5 ).onChange((newValue) => {
        this.ChangeLight("AmbientIntensity", "SunsetLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "sunset_AmbientColor").onChange((newColor) => {
        this.ChangeLight("AmbientColor","SunsetLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add( this.settings,"sunset_HemiIntensity",0,5).onChange((newValue) => {
        this.ChangeLight("HemiIntensity", "SunsetLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "sunset_HemiLightSkyColor").onChange((newColor) => {
        this.ChangeLight("HemiLightSkyColor","SunsetLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        })); 
      });

      this.folders.LightDay.addColor(this.settings, "sunset_HemiLightFloorColor").onChange((newColor) => {
        this.ChangeLight("HemiLightFloorColor","SunsetLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });


       this.folders.SunsetSky = this.gui.addFolder("Sunset - Sky");
      this.folders.SunsetSky.addColor(this.settings, "sunset_sky_color_top").onChange((newColor) => {
        
        console.log("Sunset" , newColor);

        this.ChangeLight("sky_color_top","SunsetLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      this.folders.SunsetSky.addColor(this.settings, "sunset_sky_color_center").onChange((newColor) => {
        this.ChangeLight("sky_color_center","SunsetLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      
      this.folders.SunsetSky.addColor(this.settings, "sunset_sky_color_bottom").onChange((newColor) => {
        this.ChangeLight("sky_color_bottom","SunsetLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });



/** Night light Settings */
 this.folders.LightDay = this.gui.addFolder("Night - Light Settings");
      this.folders.LightDay.add( this.settings, "night_SunIntensity", 0, 5 ).onChange((newValue) => {
        this.ChangeLight("SunIntensity", "NightLightSetting" , newValue);        
      });

      this.folders.LightDay.addColor(this.settings, "night_SunColor").onChange( (newColor) => {
        this.ChangeLight("SunColor","NightLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add(this.settings, "night_AmbientIntensity", 0, 5 ).onChange((newValue) => {
        this.ChangeLight("AmbientIntensity", "NightLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "night_AmbientColor").onChange((newColor) => {
        this.ChangeLight("AmbientColor","NightLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.LightDay.add( this.settings,"night_HemiIntensity",0,5).onChange((newValue) => {
        this.ChangeLight("HemiIntensity", "NightLightSetting" , newValue);
      });

      this.folders.LightDay.addColor(this.settings, "night_HemiLightSkyColor").onChange((newColor) => {
        this.ChangeLight("HemiLightSkyColor","NightLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        })); 
      });

      this.folders.LightDay.addColor(this.settings, "night_HemiLightFloorColor").onChange((newColor) => {
        this.ChangeLight("HemiLightFloorColor","NightLightSetting" , Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }));
      });

      this.folders.NightSky = this.gui.addFolder("Night - Sky");
      this.folders.NightSky.addColor(this.settings, "night_sky_color_top").onChange((newColor) => {
        this.ChangeLight("sky_color_top","NightLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      this.folders.NightSky.addColor(this.settings, "night_sky_color_center").onChange((newColor) => {
        this.ChangeLight("sky_color_center","NightLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });
      
      this.folders.NightSky.addColor(this.settings, "night_sky_color_bottom").onChange((newColor) => {
        this.ChangeLight("sky_color_bottom","NightLightSetting" , Array.isArray(newColor) ? Utility.RGBToHex({
          r : Math.floor(newColor[0]),
          g : Math.floor(newColor[1]),
          b : Math.floor(newColor[2])
        }) : newColor);
      });


      ///
      this.folders.vr = this.gui.addFolder("VR");
      this.folders.vr
        .add(this.settings, "SetVRCamera")
        .name("Set Camera to VR");
      this.folders.vr
        .add(this.settings, "GetVRPosition")
        .name("Get VR Position");

      this.folders.vr.open();



      // this.folders.post = this.gui.addFolder("LookUpTable");
      // this.folders.post
      //   .add(this.settings, "lut", [
      //     "Chemical",
      //     "Clayton",
      //     "Cubicle",
      //     "Remy",
      //     "Bourbon",
      //     "RoadRunner",
      //     "Luminous",
      //     "WarmTeal",
      //     "Optima",
      //     "Emulation",
      //   ])
      //   .onChange((newValue) => {
      //     console.log(this.$store.state.world.mainScene.xr.Renderer.lutMap, newValue);
      //     this.$store.state.world.mainScene.xr.Renderer.postprocessing.lutPass.uniforms.lut.value = this.$store.state.world.mainScene.xr.Renderer.lutMap[
      //       newValue
      //     ];
      //     console.log(
      //       newValue,
      //       this.$store.state.world.mainScene.xr.Renderer.postprocessing.lutPass.uniforms.lut
      //         .value
      //     );
      //     //this.$store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value = newValue;
      //   });

      /*this.$store.watch(
        (state) => state.viewMode,
        (newValue, oldValue) => {
          console.log("VIEW MODE ",newValue)
          if (this.$store.state.content.currentViewContent != null) {
            this.settings.mainLightIntensity = this.$store.state.content.currentViewContent.MainLightIntensity;
            this.settings.hemiLightIntensity = this.$store.state.content.currentViewContent.AmbientLightIntensity;
            console.log(this.$store.state.content.currentViewContent)
            console.log(this.settings)
          }
        }
      );*/
    },
    
    

    
  },
};
</script>

<style lang="scss">
.dg.ac {
  z-index: 999999;
}

.dg li {
  margin: 0;
}
.dg.main.taller-than-window .close-button {
  border-top: 1px solid #ddd;
}

.dg.main .close-button {
  background-color: #ccc;
}

.dg.main .close-button:hover {
  background-color: #ddd;
}

.dg {
  color: #555;
  text-shadow: none !important;
}

.dg.main::-webkit-scrollbar,
.dg.main::-moz-scrollbar {
  background: #fafafa;
}

.dg.main::-webkit-scrollbar-thumb, 
.dg.main::-moz-scrollbar-thumb {
  background: #bbb;
}

.dg li:not(.folder) {
  background: #fafafa;
  border-bottom: 1px solid #ddd;
}

.dg li.save-row .button {
  text-shadow: none !important;
}

.dg li.title {
  background: #e8e8e8
    url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==)
    6px 10px no-repeat;
}

.dg .cr.function:hover,
.dg .cr.boolean:hover {
  background: #fff;
}

.dg .c input[type="text"] {
  background: #e9e9e9;
}

.dg .c input[type="text"]:hover {
  background: #eee;
}

.dg .c input[type="text"]:focus {
  background: #eee;
  color: #555;
}

.dg .c .slider {
  background: #e9e9e9;
}

.dg .c .slider:hover {
  background: #eee;
}

div#stats {
  right: 0;
}
</style>