import Utility from "../utils";
import axios from 'axios';
import config from '../../main.config';

import {Shadow, Car, Pump} from '../Enum/Enums';
import IOMixins from "../Mixins/IOMixins";
import mainConfig from "../../main.config";

export default {
  mixins: [IOMixins],
  methods: {
    urltoFile(url, filename, mimeType){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    },
    SaveScreenshot(){
      function delay(t, data) {
        return new Promise(resolve => {
            setTimeout(resolve.bind(null, data), t);
        });
      }
      /* Check if converted Values in Object are correct and everything is converted */
      const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));
      var imgObjects = {};


      console.log("SaveScreenshot",  this.$store.state.area.current);
      var allCombinations;


      if(this.$store.state.area.current.name == "ApartmentBuilding"){
        
        allCombinations = cartesian(
          [ 'operatorConcept_OnlyOperatorCurrent', 'operatorConcept_OperatorCurrentAndCommunity' ],
          [ 'car_Car', 'car_NoCar' ],
          [ 'pump_Pump', 'pump_NoPump' ],
          [ 'fascade_Fascade', 'fascade_NoFascade' ],
          [ 'greenRoof_GreenRoof', 'greenRoof_NoGreenRoof' ],
          [ 'battery_Battery', 'battery_NoBattery' ],
          ).map(arr => arr.join("__"));
          
          console.log(allCombinations);
          
          
          this.$store.state.world.mainScene.dashboardController.ToggleKabel(false);
          this.$store.state.world.mainScene.xr.Renderer.instance.domElement.parentNode.style.width = 1024 + "px";
          this.$store.state.world.mainScene.xr.Renderer.instance.domElement.parentNode.style.height = 1024 + "px";
          this.$store.state.world.mainScene.xr.Renderer.Resize();
          
          this.$store.state.world.mainScene.xr.Controls.SetPosition( 27.35769414335204, 30.251607938197115, 22.825167192088138);
          
          this.$store.state.world.mainScene.xr.Controls.SetTarget( -0.34593252543393915, 7.822311044023382, -1.1783807217558435);


      }else{

        allCombinations = cartesian(
          [ 'houseType_Sattel', 'houseType_Zelt', 'houseType_Flach' ],
          [ 'pvSize_PVGross', 'pvSize_PVKlein', 'pvSize_PVZiegel' ],
          [ 'car_Car', 'car_NoCar' ],
          [ 'shadow_Shadow', 'shadow_NoShadow' ],
          [ 'pump_Pump', 'pump_NoPump' ]
          ).map(arr => arr.join("__"));
          
          console.log(allCombinations);
          
          
          this.$store.state.world.mainScene.dashboardController.ToggleKabel(false);
          this.$store.state.world.mainScene.xr.Renderer.instance.domElement.parentNode.style.width = 1024 + "px";
          this.$store.state.world.mainScene.xr.Renderer.instance.domElement.parentNode.style.height = 1024 + "px";
          this.$store.state.world.mainScene.xr.Renderer.Resize();
          
          this.$store.state.world.mainScene.xr.Controls.SetPosition(-10.36,10.362,11.83);
          
          this.$store.state.world.mainScene.xr.Controls.SetTarget(0.711,1.05,1.08);
          
        }


        
        this.Get( "/ui-section-config").then((configObject)=>{
          console.log(configObject.data.Screenshots, configObject.data.Screenshots_ApartmentBuilding );

          const imageType = this.$store.state.area.current.name == "ApartmentBuilding" ? "Screenshots_ApartmentBuilding" : "Screenshots";

          var deletePromises = configObject.data[imageType].map((img)=>{
            if(img == null || img.Screenshot_image == null){return null;}
            var id = img.Screenshot_image.id;

            return this.Delete(config.CMS_BASE_URL + "/upload/files/"+ id);

          }).filter(el => el != null);

          Promise.all(deletePromises).then(()=>{
            console.log("alle Bilder vom Server gelöscht");
          

              
              var promises = allCombinations.map((config, i)=>{
                var settings = config.split("__");
                return new Promise(resolve =>
                    setTimeout(() => {
                      
                      this.$store.commit("area/ChangeMultipleGlobalSettings" , settings);
                      
                      setTimeout(()=>{
                        var imgData = this.$store.state.world.mainScene.xr.Renderer.instance.domElement.toDataURL( "image/jpeg" );
                            imgData = imgData.replace("image/jpeg", "image/octet-stream");

                          // this.downloadImage(imgData, config + '.jpg');

                          imgObjects[config] = imgData;

                      }, 200);
                      

                      resolve();
                    }, 500 * i)
                  );          
                  

              });

              //Alle Screenshots wurden gemacht
              Promise.all(promises).then(()=>{
                console.log("Alle screenshots wurden gemacht");

                var images = [];
                var imgPromises = Object.keys(imgObjects).map((imgID)=>{
                  
                  // Dataurl To File
                  return this.urltoFile(imgObjects[imgID],'preview_'+ imgID +'.jpg', 'image/jpeg' ).then(file => {
                    var formData = new FormData();
                        formData.append("files", file);
            
                        return formData;
                  }).then(formData => {
                    
                    console.log("start uploading " , imgID);
                    
                    return this.PostData(config.CMS_BASE_URL + "/upload/", formData).then(response =>{
                    
                      return {
                        Name : imgID,
                        Screenshot_image : response.data[0]
                      };
                    }).then((data)=>{
                      images.push(data);
                    });
                  
                  });
                });

                Promise.all(imgPromises).then((data)=>{
                  
                  console.log("alle Screenshots wurden hochgeladen");

                  const uploadType = this.$store.state.area.current.name == "ApartmentBuilding" ? "Screenshots_ApartmentBuilding" : "Screenshots";

                  var uploadContent = {};

                  uploadContent[uploadType] = images;

                  this.Put(config.CMS_BASE_URL + "/ui-section-config", uploadContent).then(()=>{
                    console.log("saved all images");
                  });
                })


              });

          });
        });
    },
    
    downloadImage(data, filename = 'untitled.jpeg') {
      var a = document.createElement('a');
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    },
    SetUISectionCameraPosition(path) {
      var pos = this.$store.state.world.mainScene.xr.Controls.GetCameraPosition();
      var position = { x: pos.x, y: pos.y, z: pos.z };
      var tar = this.$store.state.world.mainScene.xr.Controls.GetTarget();
      var target = { x: tar.x, y: tar.y, z: tar.z };

      axios({
        method: "put",
        url: mainConfig.CMS_BASE_URL + "/" + path,
        data: {
          Position: position,
          Target: target,
          position: position,
          target: target,
        },
      }).then((response) => {
        if (response.status === 200) {
          alert("Yes! UI Seciton Camera Positoons wurde gesetzt: ", path);
        }
        console.log(response);
      });
    },

    SaveCameraPosition() {
      console.log("SAVE CAMERA POSITIN FOR CURRENT VIEW ", this.$route.name);

      if (this.$route.name != "Explorer") {
        var path = null;

        switch (this.$route.name) {
          case "Dashboard":
            path = "ui-section-dashboard";
            break;
          case "Configurator":
            path = "ui-section-config";
            break;
          case "ExplorerSingle":
            path = "ui-section-explorer";
            break;
          default:
            break;
        }
        this.SetUISectionCameraPosition(path);
      } else {
        const subTab = this.$store.state.content.currentSubTab;
        if (subTab == null) {
          return;
        }

        const id = this.$store.state.content.currentPOI.id;

        var subTabData = this.$store.state.content.currentPOI.SubTabs.map((subTab) => {
          var obj = {
            id: subTab.id,
            __component: "sub-tab-component.sub-headline",
          };

          if (subTab.id === this.$store.state.content.currentSubTab.id) {
            var pos = this.$store.state.world.mainScene.xr.Controls.GetCameraPosition();
            obj.subtabPosition = { x: pos.x, y: pos.y, z: pos.z };

            var target = this.$store.state.world.mainScene.xr.Controls.GetTarget();
            obj.subtabTarget = { x: target.x, y: target.y, z: target.z };
          }

          return obj;
        });

        axios({
          method: "put",
          url: mainConfig.CMS_BASE_URL + "/" +"points/" + id,
          data: {
            SubTabs: subTabData,
          },
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! Subtab Position wurde gesetzt");
          }
          console.log(response);
        });
      }
    },
    SaveVRSettings(position,target,scale) {
      console.log("SAVE VR Settings ", this.$route.name);

      if (this.$route.name != "Explorer") {
        var path = null;

        switch (this.$route.name) {
          case "Dashboard":
            path = "ui-section-dashboard";
            break;
          case "Configurator":
            path = "ui-section-config";
            break;
          case "ExplorerSingle":
            path = "ui-section-explorer";
            break;
          default:
            break;
        }
        console.warn("Du musst in einem Subtab sein um diese Aktion durchzuführen")
        return;
        //this.SetUISectionCameraPosition(path);
      } else {
        const subTab = this.$store.state.content.currentSubTab;
        if (subTab == null) {
          return;
        }

        const id = this.$store.state.content.currentPOI.id;

        var subTabData = this.$store.state.content.currentPOI.SubTabs.map((subTab) => {
          var obj = {
            id: subTab.id,
            __component: "sub-tab-component.sub-headline",
          };

          if (subTab.id === this.$store.state.content.currentSubTab.id) {
            
            obj.subtabVR_Position = position;
            obj.subtabVR_Target = target;
            obj.subtabVR_Scale = scale;

          }

          return obj;
        });

        axios({
          method: "put",
          url: "https://invisibles.xyz/points/" + id + "/",
          data: {
            SubTabs: subTabData,
          },
          // headers: {
          //   'Access-Control-Allow-Origin': '*',
          //   'Content-Type': 'application/json',
          // },
          //withCredentials: true,
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! Subtab VR Setting wurde gesetzt");
          }
          console.log(response);
        });
      }
    },
  }
}