var Enums = {
HouseType:{
    Zelt: "Zelt",
    Sattel: "Sattel"
},
Seasons:{
  Summer: {
    Value: "Summer",
    Name : "Sommer"
  },
  Autumn: {
    Value: "Autumn",
    Name : "Herbst"
  },
  Winter: {
    Value : "Winter",
    Name : "Winter"
  },
  Spring:{
    Value: "Spring",
    Name : "Frühling"
  }
},
Tabs:{
    Scenarios: "Scenarios",
    Config: "Configuration",
    Info: "Information"
},
Zeiten : {
  MORGEN : "06:00 - 09:00",
  VORMITTAG : "09:00 - 12:00",
  MITTAG : "12:00 - 15:00",
  NACHMITTAG : "15:00 - 18:00",
  ABEND : "18:00 - 21:00",
  NACHT : "21:00 - 06:00"

}

}

export default Enums;