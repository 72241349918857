import click from "../../sounds/compressed/IR_SOLAR_Click_Sound.oggvorbis.ogg";
//import wheel_click from "../../sounds/compressed/IR_SOLAR_Sun_Wheel_Click.oggvorbis.ogg";
import wheel_loop from "../../sounds/compressed/IR_SOLAR_Sun_Wheel_Loop.oggvorbis.ogg";
import wheel_release from "../../sounds/compressed/IR_SOLAR_Sun_Wheel_Release.oggvorbis.ogg";


import Spring from "../../sounds/compressed/IR_SOLAR_Frhling.oggvorbis.ogg";
import Autumn from "../../sounds/compressed/IR_SOLAR_Herbst.oggvorbis.ogg";
import Summer from "../../sounds/compressed/IR_SOLAR_Sommer.oggvorbis.ogg";
import Winter from "../../sounds/compressed/IR_SOLAR_Winter.oggvorbis.ogg";
import Night from "../../sounds/compressed/IR_SOLAR_Nachts.oggvorbis.ogg";

import room_basement from "../../sounds/compressed/IR_SOLAR_Keller.oggvorbis.ogg";
import room_kitchen from "../../sounds/compressed/IR_SOLAR_Kche_Hintergrund.oggvorbis.ogg";

import basement_battery from "../../sounds/compressed/IR_Solar_Keller_Stromzhler.oggvorbis.ogg";
import basement_meter01 from "../../sounds/compressed/IR_Solar_Keller_Stromzhler-01.oggvorbis.ogg";
import basement_meter from "../../sounds/compressed/IR_Solar_Keller_Stromzhler.oggvorbis.ogg";

import kitchen_topf from "../../sounds/compressed/IR_SOLAR_Kche_Kochtopf.oggvorbis.ogg";
import kitchen_boiler from "../../sounds/compressed/IR_SOLAR_Kche_Wasser_Kocher.oggvorbis.ogg";

import TinyCity from "../../sounds/compressed/DGS_Solarzentrum_TinyCity_Sound_Loop_01.ogg";
import UndergroundGarage from "../../sounds/compressed/DGS_Solarzentrum_UndergroundGarage_Sound_Loop.oggvorbis.ogg";
import Balcony from "../../sounds/compressed/DGS_Solarzentrum_Balcony_Sound_Loop.oggvorbis.ogg";
import Hallway from "../../sounds/compressed/DGS_Solarzentrum_Hallway_Sound_Loop.oggvorbis.ogg";
import LivingRoom from "../../sounds/compressed/DGS_Solarzentrum_LivingRoom_Sound_Loop.oggvorbis.ogg";

import InstallationsRoom from "../../sounds/compressed/DGS_Solarzentrum_MFHBasement_Sound_Loop.ogg";




const AudioFiles = {
  menu : {
    click : click,

    //wheel_click: wheel_click,
    wheel_loop: wheel_loop,
    wheel_release: wheel_release,
  },
  season : {
    Spring : Spring,
    Summer : Summer,
    Autumn : Autumn,
    Winter : Winter,
  },
  time : {
    night : Night
  },
  room : {
    tinycity : TinyCity,
    basement : room_basement,
    kitchen : room_kitchen,
    balcony : Balcony,
    undergroundGarage : UndergroundGarage,
    hallway : Hallway,
    livingRoom : LivingRoom,
    installationsRoom: InstallationsRoom
  },
  basement : {
    battery : basement_battery,
    meter : basement_meter,
    meter01 : basement_meter01,
  },
  kitchen : {
    topf : kitchen_topf,
    boiler : kitchen_boiler
  }

}

export default AudioFiles;


