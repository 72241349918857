export enum Area {
  FamilyHouse = "FamilyHouse",
  ApartmentBuilding = "ApartmentBuilding",
  Apartment = "Apartment"
}

export enum Orientation {
  North = "North",
  East = "East",
  South = "South",
  West = "West"
}

export enum Shadow {
  Shadow = "Shadow",
  NoShadow = "NoShadow"
}

export enum Pump {
  Pump = "Pump",
  NoPump = "NoPump"
}

export enum Pumpinverter {
  Pumpinverter = "Pumpinverter",
  NoPumpinverter = "NoPumpinverter"
}

export enum AP_Pumpinverter {
  Pumpinverter = "Pumpinverter",
  NoPumpinverter = "NoPumpinverter"
}

export enum Battery {
  Battery = "Battery",
  NoBattery = "NoBattery"
}

export enum Car {
  Car = "Car",
  NoCar = "NoCar"
}

export enum HouseType {
  Sattel = "Sattel",
  Zelt = "Zelt",
  Flach = "Flach"
}

export enum PVSize {
  PVGross = "PVGross",
  PVKlein = "PVKlein",
  PVZiegel = "PVZiegel"
}

export enum GreenRoof {
  GreenRoof = "GreenRoof",
  NoGreenRoof = "NoGreenRoof"
}

export enum Fascade {
  Fascade = "Fascade",
  NoFascade = "NoFascade"
}

export enum ViewMode {
  dashboard = "dashboard",
  explorer = "explore",
  configurator = "configurator",
  tinyCity = "tinyCity"
}

export const Time = [
  "06:00 - 09:00 Uhr",
  "09:00 - 12:00 Uhr",
  "12:00 - 15:00 Uhr",
  "15:00 - 18:00 Uhr",
  "18:00 - 21:00 Uhr",
  "21:00 - 06:00 Uhr"
]

export const TimeShort = [
  "Morgens",
  "Vormittags",
  "Mittags",
  "Nachmittags",
  "Abends",
  "Nachts"
]

export enum Season {
  Spring = "Frühling",
  Summer = "Sommer",
  Autumn = "Herbst",
  Winter = "Winter"
}

export enum SVGIcon {
  Strommast = "Strommast",
  Solarmodul = "Solarmodul",
  Modulmontage = "Modulmontage",
  ModulmontageBalkon = "ModulmontageBalkon",
  Batterie = "Batterie",
  EAuto = "EAuto",
  EAutoFalse = "EAutoFalse",
  Verschattung = "Verschattung",
  Kueche = "Kueche",
  Ausrichtung = "Ausrichtung",
  Pump = "Pump",
  Betriebsstrom = "Betriebsstrom",
  Balkon = "Balkon",
  Messkonzept = "Messkonzept",
  Betreiberkonzept = "Betreiberkonzept",
  Beteiligungsquote = "Beteiligungsquote",
  Solarpflicht = "Solarpflicht",
  Eigentum = "Eigentum",
  MieterstromInitiieren = "MieterstromInitiieren"
}


export enum Room {
  House = "House",
  ApartmentBuilding = "ApartmentBuilding",
  Kitchen = "Kitchen",
  Basement = "Basement",
  Solarzelle = "Solarzelle",
  UndergroundGarage = "UndergroundGarage",
  InstallationsRoom = "InstallationsRoom",
  Balcony = "Balcony",
  Hallway = "Hallway",
  Livingroom = "Livingroom",
  TinyCity = "TinyCity"
}


export enum OperatorConcept {
  OnlyOperatorCurrent = "OnlyOperatorCurrent",
  OperatorCurrentAndCommunity = "OperatorCurrentAndCommunity"
}
// Flach
// NoShadow
// PvGross
// PvKlein
// PvZiegel
// Sattel
// Shadow
// Zelt