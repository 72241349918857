<template>
  <div v-html="compiledMarkdown"></div>
</template>

<script>
import {marked} from 'marked';
import config from '../../main.config';

export default {
  name : "VueMarkdown",
  props: {
    source: { default: '', type: String },
  },
  computed: {
    compiledMarkdown: function () {

      let text = this.PreRender(this.source);

      text = typeof(text) == "undefined" || text == null ? "" : text;

      return marked(text, { breaks: true, highlight: (code, lang)=>{console.log("marked" , code, lang); return code;} })
    }
  },
  methods:{
    PreRender(text){

      if(text == null){return null;}
      
      text = text.replaceAll("](/uploads/", "]("+ config.CMS_BASE_URL  +"/uploads/");

      return text;
    },
    
  }
}
</script>