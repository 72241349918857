import AudioFiles from './AudioFiles';
import { Room } from '../../Enum/Enums';

class AudioController {
  constructor(store) {
    this.store = store;
    // Create a gain node and set its gain value to 0.5

    this.loaded = false;
    this.enabled = false;
    this.initialized = false;
    this.buffers = {};
    this.arrayBuffers = {};

    this.currentPlaying = {};

    store.commit("audio/SetAudioController", this);

    this.loader = this.LoadSounds();

    store.watch(state => state.audio.mute, this.ToggleSound);

    store.watch(state => state.content.currentPOI, (newValue, oldValue) => {
      this.PlayRoomSounds(newValue, oldValue);   
    });
    
    store.watch(state => state.area.season, (newValue, oldValue) => {
      this.PlayRoomSounds(store.state.content.currentPOI, store.state.content.currentPOI);   
    });
    
    store.watch(state => state.area.current, (newValue, oldValue) => {
      this.PlayRoomSounds(store.state.content.currentPOI, store.state.content.currentPOI);   
    });
    
    store.watch(state => state.viewMode, (newValue, oldValue) => {
      this.PlayRoomSounds(null, null);   
    });
    
  }
  
  async LoadArrayBufferFile(filepath, category, soundName) {

    const response = await fetch(filepath);
    const arrayBuffer = await response.arrayBuffer();

    this.arrayBuffers[category][soundName] = arrayBuffer;
    return arrayBuffer;

  }
  
  LoadSounds = ()=>{
    var loadArray = [];

    Object.keys(AudioFiles).map((category) => {
      if (!this.arrayBuffers.hasOwnProperty(category)) {
        this.arrayBuffers[category] = {}
      }
      return Object.keys(AudioFiles[category]).map((soundName) => {

        loadArray.push(this.LoadArrayBufferFile(AudioFiles[category][soundName], category, soundName));

      });
    });

    return Promise.all(loadArray).then(arrayBuffers => {
      this.loaded = true;

      console.log("Sounds are loaded", this.arrayBuffers);
      return this.arrayBuffers;
    });
  }

  
  ToggleSound = (value) => {
    if (value) {
      this.StopAll();
    } else {
      this.Resume();
    }
  }

  PlayRoomSounds = (newPOI, oldPOI) => {
    
    console.log("PlayRoomSounds" , oldPOI, newPOI);

    this.StopAll();

      if( ['explore' , 'dashboard', 'configurator'].includes(this.store.state.viewMode)){
        if(this.store.state.content.currentPOI == null){
          this.PlaySound('season.' + this.store.state.area.season, true);
        }
      }else if(this.store.state.viewMode == "tinyCity"){
        this.PlaySound("room.tinycity", true, 1);

        console.log("Play TinyCity Sound");
        return;
      }
      
    //PlaySounds
    if (newPOI != null) {
      switch (newPOI.Room.SceneRoom) {
        case Room.House:
          this.PlaySound("season." + this.store.state.area.season, true, 1);
        break;
        case Room.ApartmentBuilding:
          this.PlaySound("season." + this.store.state.area.season, true, 1);
        break;
        case Room.Basement:
          this.PlaySound("room.basement", true, 1);
          break;
        case Room.InstallationsRoom:
          this.PlaySound("room.installationsRoom", true, 1);
          break;
        case Room.Kitchen:
          this.PlaySound("room.kitchen", true, 1);
        break;
        case Room.Hallway:
          this.PlaySound("room.hallway", true, 1);
        break;
        case Room.UndergroundGarage:
          this.PlaySound("room.undergroundGarage", true, 1);
        break;
        case Room.Balcony:
          this.PlaySound("room.balcony", true, 1);
        break;
        case Room.Livingroom:
          this.PlaySound("room.livingRoom", true, 1);
        break;
      }
    }
  }

  EnableSounds = () => {

    if(this.enabled){return;}
    this.StopAll();

    this.enabled = true;
    this.context = this.context == null ? new AudioContext() : this.context;
    this.context.resume();

  }

  async InitAudioArrayBuffers(){
    var promises = [];
    Object.keys(this.arrayBuffers).map((category)=>{
      if(!Object.prototype.hasOwnProperty.call(this.buffers,category)){
        this.buffers[category] = {};
      }

      Object.keys(this.arrayBuffers[category]).map((soundName)=>{
        promises.push(
            this.context.decodeAudioData(this.arrayBuffers[category][soundName]).then((audioBuffer)=>{

            this.buffers[category][soundName] = {
              isPlaying: false,
              buffer : audioBuffer,
              source:  this.context.createBufferSource(),
              gainNode: null,
              loop: false,
              paused: false,
              volume: 1
            }
          })
        );
      });
    });

    return Promise.all(promises).then(()=>{
      this.initialized = true;
    })

  }


  PlaySound = async (name, loop = false, volume = 1.0) => {

    if(!this.enabled || !this.loaded){return;}

    if(!this.initialized && this.loaded){

      console.log("await");
      await this.InitAudioArrayBuffers();
      console.log("await finish");
    }
    
    var [category, soundName] = name.split(".");


    if (this.store.state.audio.mute) {
      volume = 0;
    }

    //Check if sound is playing
    if(this.currentPlaying.hasOwnProperty(category + '.' + soundName)){
      if(this.currentPlaying[category + '.' + soundName]){
        return;
      }
    }

    this.buffers[category][soundName].isPlaying = true;
    this.buffers[category][soundName].volume = volume;
    this.buffers[category][soundName].loop = loop;

    this.buffers[category][soundName].source = this.context.createBufferSource(); // creates a sound source
    this.buffers[category][soundName].source.buffer = this.buffers[category][soundName].buffer;                    // tell the source which sound to play
    this.buffers[category][soundName].source.loop = loop;

    if (this.buffers[category][soundName].gainNode == null) {
      this.buffers[category][soundName].gainNode = this.context.createGain();
      this.buffers[category][soundName].gainNode.gain.value = volume;
      this.buffers[category][soundName].gainNode.connect(this.context.destination);
    } else {
      if (this.store.state.audio.mute) {
        this.buffers[category][soundName].gainNode.gain.value = volume;
      } else {
        this.buffers[category][soundName].gainNode.gain.setTargetAtTime(volume, this.context.currentTime, .6);
      }
    }

    this.buffers[category][soundName].source.connect(this.buffers[category][soundName].gainNode);
    this.buffers[category][soundName].source.onended = () => {
      if(!this.buffers[category][soundName].loop){
        this.buffers[category][soundName].isPlaying = false;

        this.currentPlaying[category + '.' + soundName] = false;
      }
    };

    this.buffers[category][soundName].source.start(0);
    this.currentPlaying[category + '.' + soundName] = true;
  }

  StopSound = (name) => {
    var [category, soundName] = name.split(".");
    //falls noch nicht geladen ist ODER der sound gar nicht spielt
    if (!this.loaded || this.buffers[category][soundName].source == null) { return; }

      this.buffers[category][soundName].paused = true;
      this.buffers[category][soundName].source.stop ? this.buffers[category][soundName].source.stop(0) : this.buffers[category][soundName].source.noteOff(0);


      this.buffers[category][soundName].isPlaying = false;
      this.currentPlaying[category + '.' + soundName] = false;
  }

  StopAll = () => {
    Object.keys(this.buffers).map(category => {
      Object.keys(this.buffers[category]).filter(sound => this.buffers[category][sound].isPlaying).map(soundName => {
        this.StopSound(category + "." + soundName);
      });
    });
  }

  Resume = () => {

    console.log("Resume");
    this.PlayRoomSounds(this.store.state.content.currentPOI,this.store.state.content.currentPOI)
  }
}



export default AudioController;