<template>
  <div class="component checkbox" :class="{disabled: disabled}">
    <label class="checkbox-label no-pseudo" :for="id + '_checkbox'">{{leftTitle}}</label>
    <input :disabled="disabled" :id="id + '_checkbox'" type="checkbox" :checked="checked" @input="onChange"/>
    <label class="checkbox-label pseudo" :for="id + '_checkbox' ">{{title}}</label>
  </div>
</template>

<script>
export default {
  name: "LeftRightCheckbox",
  props:{
    title :{
      type : String,
      default : ""
    },
    leftTitle :{
      type : String,
      default : ""
    },
    checked :{
      type : Boolean,
      default: false,
      required : true
    },
    disabled : {
      type :Boolean,
      default : false,
      required: false
    }
  },
  data: function(){
    return {
      id : null

    }
  },
  mounted(){
    this.id = this._uid;
  }, 
  methods: {
    onChange: function(e){
      this.$emit("onChange", e.target.checked);
    }
  }
}
</script>

<style lang="scss" scoped>
.component.checkbox{
  margin-bottom: .5rem;
  height: 24px;
}

input[type="checkbox"]{
  display: none;
  position: absolute;
}

.checkbox-label{
  display: inline-block;
  position: relative;
  padding-left:70px;
  cursor: pointer;

  .disabled &{
    color : #ccc;

    &:before, &:after{
      border: 2px solid #ccc!important;
    }
    &:before{
      background:#eee!important;
    }
    &:after{
      background:#ccc!important;
    }
  }

  &.pseudo{
  &:before, &:after{
    content: "";
    position: absolute;
    
    display:block;
    
   
    border-radius:20px;
    background: $light-orange;
  }

  &:before{
    top:0;
    left:0;
    height:20px;
    width:60px;
     border:2px solid $orange;
    box-shadow: inset 2px 2px 3px 0 rgba(0,0,0,.2);
  }

  &:after{
    top: 0px;
    left: -2px;
    height: 20px;
    width: 27px;
    background: $orange;
     border:2px solid $orange;
    transition-duration: .2s;
  }
  }
}


.no-pseudo{
  display: inline-block;
  padding-left:0;
  margin-right: 1rem;
}


input[type="checkbox"]:checked + label.pseudo{
  
  &:after{
    left:35px;
  }
}

</style>