<template>
  <div class="component radio">

    <template v-for="(option,index) in options">
      <div class="option" :class="{'selected' : $store.state.area.houseType == option.value}" v-bind:key="option.name">
        <input :disabled="option.disabled" :checked="option.value == selected" type="radio" :id="id + index"  :name="id" :value="option.value" v-on:change="e => onChange(e, option)"/>
        <label class="radio-label" :class="{disabled : option.disabled}" :for="id + index">
          <template v-if="option.icon == 'Zelt'"><ZeltIcon :state="$store.state.area.houseType == HouseType.Zelt ? 1 : 0"/></template>
          <template v-if="option.icon == 'Sattel'"><SattelIcon :state="$store.state.area.houseType == HouseType.Sattel ? 1 : 0"/></template>
          <template v-if="option.icon == 'Flach'"><FlachIcon :state="$store.state.area.houseType == HouseType.Flach ? 1 : 0"/></template>
        </label>
      </div>
    </template>

<!-- 

    <input :id="id + '_checkbox'" type="checkbox" :checked="checked" @input="onChange"/>
    <label class="checkbox-label" :for="id + '_checkbox' ">{{title}}</label> -->
  </div>
</template>

<script>

import SattelIcon from '../../SVG/Configurator/DGSZentrum_IconSet_Sattel_Icon.vue';
import ZeltIcon from '../../SVG/Configurator/DGSZentrum_IconSet_Zelt_Icon.vue';
import FlachIcon from '../../SVG/Configurator/DGSZentrum_IconSet_Flach_Icon.vue';

import {HouseType} from "../../Enum/Enums";

export default {
  name: "Checkbox",
  components:{
    SattelIcon,
    ZeltIcon,
    FlachIcon,
  },
  props:{
    title :{
      type : String,
      default : ""
    },
    options:{
      type: Array,
      default : ()=>([])
    },
    selected : {
      type : String,
      required : true
    },
    
  },
  data: function(){
    return {
      id : null,
      HouseType

    }
  },
  mounted(){
    this.id = this._uid;
  }, 
  methods: {
    onChange: function(e, option){
      if(option.disabled){return;}
      this.$emit("onChange", option);
    }
  }
}
</script>

<style lang="scss" scoped>
.component.radio{
  margin-bottom: .5rem;
  display: flex;
}

input[type="radio"]{
  display: none;
  position: absolute;
}

.option {
  
  text-align: center;
  background: #fff;
  margin-right: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0,0,0,.2);

  &:hover{
    background: #ccc;
  }

  &.selected{
    background:$orange;
  }
}

.radio-label{
  display: block;
  position: relative;
  //padding-left:30px;
  min-height: 25px;
  cursor: pointer;

  &.disabled{
    background:#ccc;
    cursor: not-allowed;

    svg path {
        fill: #eee!important;
      
    }
  }

  svg {
    width: 45px;
  }
}

input[type="radio"]:checked + label{
  &:before{
    background: $light-orange;
    border: 2px solid $orange;
  }
  
  &:after{
    background: $orange;
  }
}

</style>

<style lang="scss">
.radio-label.disabled svg * {fill: #eee;}
</style>