<template>
  <div
    :class="
      'icon-config-button ' +
      (active ? 'active' : '') +
      (disabled ? 'disabled' : '')
    "
  >
    <button @click="ClickHandler" :disabled="disabled">
      <slot :active="active" /><span v-html="Name" />
    </button>
  </div>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    Name: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    ClickHandler: function () {
      if (!this.disabled) {
        this.$emit("clicked");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-config-button {
  &.active button {
    background: $orange;
    color: #fff;
    span {
      color: #fff;
    }
  }

  &.disabled button {
    background: $light-grey;
    color: $dark-grey;
    span {
      color: $dark-grey;
    }
  }

  button {
    background: #fff;
    width: 90px;
    height: 90px;

    font-size: 10px;
    font-weight: 700;
    color: $orange;

    box-shadow: 0px 3.08602px 7.71505px -3.08602px rgba(111, 126, 145, 0.28);
    border-radius: 11.5726px;
    padding: 3px;
  }
  span {
    color: $orange;
  }
 
}

svg,
img {
  display: block;
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  height: auto;
  margin: auto;
  margin-bottom: 0.25rem;
}
</style>