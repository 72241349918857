import AreaBase from "./AreaBase";
import {Area, Room} from "../Enum/Enums";


class ApartmentArea extends AreaBase{
  constructor(props:any){
    super(props);
    this.name = Area.Apartment;
    this.routeParam = "apartment"
    this.configSettings = Object.assign(this.configSettings,{shadow:true})
    
    this.rooms.push(Room.Balcony, Room.Livingroom)
    this.defaultRoom = Room.Balcony;


    this.cmsID = 1;

    this.dataPath = '/data/solardaten_apartment.json';

  }
}

export default ApartmentArea;