import { Room } from "../Enum/Enums";
import mainConfig from "../../main.config";
import axios from 'axios';

class AreaBase {
  name: String = "";
  routeParam: String = "";
  dataPath:String =  '';
  configSettings: Object = {
    houseType: false,
    pv: false,
    operatorConcept : false,
    greenRoof: false,
    car: false,
    fascade: false,
    shadow: false,
    pump: false,
    battery: false,
  };

  dashboardSettings: Object = {
    description: true,
    seasonWheel: true,
    subBilanz: true
  };

  rooms: Array<String> = [Room.Solarzelle];
  defaultRoom: String = '';
  store: any;

  solarDaten: any = null;
  solarDatenSet: any = null;

  cmsID:number = 0;
  cmsData:Object | null= null;

  constructor(store) {
    this.store = store;
  }

  LoadCMSData = async () => {
    return axios.get(mainConfig.CMS_BASE_URL + '/areas/'+this.cmsID).then(response => {
      this.cmsData = response.data;
      return this.cmsData;
    }).catch(error => {
      console.log('error while loading data:', error);
    });
  }

  OnEnter = () => {

    if(this.solarDatenSet != null){return}
    
    return axios.get(mainConfig.PAGE_BASE_URL + this.dataPath).then(response => {
      this.solarDatenSet = response.data;
      
      this.SetSolarData(this.solarDatenSet);
    }).catch(error => {
      console.log('error while loading data:', error);
    });

    /*let stackToLoad = LoadStack.filter(stackElement => {

      return stackElement.rooms.filter(stackElementRoom => this.rooms.includes(stackElementRoom)).length > 0

    });

    stackToLoad = stackToLoad.filter(stackElement => !Object.prototype.hasOwnProperty.call(this.store.state.world.Library, stackElement.name));

    if (stackToLoad.length > 0) {
      this.store.commit("world/SetPartialLoad", true);
      this.store.state.world.mainScene.loader.loadModels(stackToLoad).then(() => {
        this.store.commit("world/SetPartialLoad", false);
      });
    }*/



  }

  OnExit = () => {
    console.log("EXIT AREA ", this.routeParam)
  }

  SetSolarData(dataset){
    this.solarDaten = dataset.HouseType_Sattel__Orientation_South__PVSize_PVGross__Season_Summer__Battery_true__car_true__pump_true__shadow_true;
  }
}
export default AreaBase;