<template>
  <div>
    <div
      class="playerPlay player flex flex-align-center"
      v-if="!this.$store.state.world.mainScene.AnimationController.isPlaying"
    >
      <PlayIcon
        :v-if="!this.$store.state.animation.isPlaying"
        @click="StartAnimation()"
        class="playIcon"
      />
    </div>
    <div
      class="playerProgress player"
      v-if="this.$store.state.world.mainScene.AnimationController.isPlaying"
    >
      <StopIcon @click="StopAnimation()" class="inline-block icons" />
      <PauseIcon @click="PauseAnimation()" class="inline-block icons" />
      <div class="loading-bar inline-block">
        <div :style="GetLoadWidth" id="loading-bar-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>

import PlayIcon from "../SVG/Play.svg";
import PauseIcon from "../SVG/Pause.svg";
import StopIcon from "../SVG/Stop.svg";
import MinusOne from "../SVG/MinusOne.svg";
import PlusOne from "../SVG/PlusOne.svg";
import { HouseType, PVSize } from "../Enum/Enums.ts";

import Utility from "./../utils";

export default {
  name: "AnimationPlayer",
  components: {
    PlayIcon,
    PauseIcon,
    StopIcon,
    MinusOne,
    PlusOne,
    HouseType,
    PVSize,
  },
  data: function () {
    return {
      mixer: null,
      mesh: null,
      animation: null,
      animationID: null,
      progress: 0,
    };
  },
  computed: {
    GetLoadWidth() {
      this.progress = this.$store.state.animation.Progress;

      return {
        width: this.progress * 100 + "%",
      };
    },
  },
  methods: {
    StartAnimation() {
      this.$store.state.world.mainScene.AnimationController.Play();
    },
    PauseAnimation() {
      this.$store.state.world.mainScene.AnimationController.Pause();
    },
    StopAnimation() {
      this.$store.state.world.mainScene.AnimationController.ResetAnimation();
    },
    SetAnimation() {
      Utility.SetAnimation(this.$store);
    },
  },
  mounted() {
    this.SetAnimation();
  },

  watch: {
    "$store.state.area.pvSize": function () {
      this.SetAnimation();
    },
    "$store.state.area.houseType": function () {
      this.SetAnimation();
    },
    "$store.state.content.currentSubTab": function () {
      this.SetAnimation();
    },
    "$store.state.currentTab": function () {
      this.SetAnimation();
    },
  },
  beforeDestroy() {
    //console.log("ANIMATION VUE DESTROY ",this.$store.state.world.mainScene.AnimationController.animation.name,this.$store.state.world.mainScene.AnimationController.clipAction.time);
    this.$store.state.world.mainScene.AnimationController.ResetAnimation();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
.playerPlay {
  left: calc(30% - 80px);
  top: calc(50% - 80px);
  height: 160px;
  width: 160px;
  margin: auto;
  border-radius: 100px;
  background-color: #fff;
  //opacity: 0.9;
}
.play-icon {
  animation: animloop 1.5s infinite;
  width: 90px;
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.player {
  position: absolute;
  pointer-events: all;
}

.playerProgress {
  left: calc(30% - 225px);
  background-color: #eee;
  border-radius: $border-radius-3;
  bottom: 0;
  padding-top: 50px;
  height: 50px;
  width: 450px;
  margin: auto;
  padding: 1rem;
}

@keyframes animloop {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.loading-bar {
  vertical-align: middle;
  height: 11px;
  width: 350px;
  border-radius: 5px;
  background-color: #fff; //$light-orange;
  overflow: hidden;
  margin-right: 10px;
}
#loading-bar-inner {
  background-color: $orange;
  height: 11px;
  position: relative;
  border-radius: 5px;
}

.icons {
  width: auto;
  position: relative;
  vertical-align: middle;
  margin-right: 3px;
}
</style>