import Utility from "../utils";
import axios from 'axios';
import mainConfig from "../../main.config";
export default {
  
  methods: {
    UpdatePostSettings(newValue, oldValue){

      if(newValue.hasOwnProperty("PostProcessingSetting")){

        var settingsCopy = Object.assign({},this.settings);

        var lightSettings = newValue.PostProcessingSetting;
        
        if(typeof(lightSettings) != "undefined"){

          Object.keys(lightSettings).map((key)=>{

            settingsCopy[key] = parseFloat(lightSettings[key]);
          });
          
          this.settings = settingsCopy;

          if(this.hasOwnProperty("updateDispay")){
            this.updateDisplay();
          }
        }
      }
    },
    updateDisplay() {
        Object.keys(this.settings).map((settingsName)=>{
          Object.keys(this.gui.__folders).map((folderName)=>{
          this.gui.__folders[folderName].__controllers.map(controller => {
            if(controller.property == settingsName){
              controller.object[settingsName] = this.settings[settingsName];
              controller.updateDisplay()
            }
          });
        });
      });

    },
    SavePostSettings(){

      var postSettingsCopy = {...this.$store.state.content.currentViewContent.PostProcessingSetting};
      
      Object.keys(postSettingsCopy).map(key =>{
        postSettingsCopy[key] = parseFloat(this.settings[key]);
      });
      //return null;

      delete postSettingsCopy.id;
     
      if (this.$route.name != "Explorer") {
        var path = null;

        switch (this.$route.name) {
          case "Dashboard":
            path = "ui-section-dashboard";
            break;
          case "Configurator":
            path = "ui-section-config";
            break;
          case "ExplorerSingle":
            path = "ui-section-explorer";
            break;
          default:
            break;
        }
        
        axios({
          method: "put",
          url: mainConfig.CMS_BASE_URL + "/" + path,
          data: {
            PostProcessingSetting : postSettingsCopy
          },
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! UI Section Lightsettings wurden gesetzt: ", path);
          }
        }).catch(err => {
          console.log(err);
        });

      }else{

        const subTab = this.$store.state.content.currentSubTab;
        if (subTab == null) {
          return;
        }

        const id = this.$store.state.content.currentPOI.id;
        var putRequest = true;
        var subTabData = this.$store.state.content.currentPOI.SubTabs.map((subTab) => {

          var obj = {
            id: subTab.id,
            __component: "sub-tab-component.sub-headline",
          };

          if (subTab.id === this.$store.state.content.currentSubTab.id) {

            if(this.$store.state.content.currentSubTab == null){
              putRequest = false;
            }
            obj.PostProcessingSetting = this.$store.state.content.currentSubTab.PostProcessingSetting === null || typeof(this.$store.state.content.currentSubTab.PostProcessingSetting) == "undefined" ? postSettingsCopy : Object.assign(this.$store.state.content.currentSubTab.PostProcessingSetting , postSettingsCopy)

          }

          return obj;
        });

        axios({
          method:putRequest ? "put" : "post",
          url: mainConfig.CMS_BASE_URL + "/points/" + id,
          data: {
            SubTabs: subTabData,
          },
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! Subtab LightSetting wurde gesetzt");
          }
        });

      }

    },
    ChangePostSetting(setting, value){
      var newPageObj = {};

      Object.assign(newPageObj, this.$store.state.content.currentViewContent);

      var postSetting = newPageObj.PostProcessingSetting;
      
      postSetting[setting] = value;

      this.$store.commit("content/SetCurrentViewContent", newPageObj);


    },
  }
}