<template>
  
    <div v-if="typeof(this.$store.state.area.current.solarDaten[SolarIndex]) != 'undefined'" class="scene-status" @mouseover="disableControls" @mouseleave="enableControls">
      <div class="flex width-12">
        
        <div class="flex width-12"> 
          <div class="card-small width-6 season flex flex-column flex-align-center flex-center">
          
            <template v-if="(this.$store.state.area.season == Enums.Seasons.Spring.Value)">
              <button class="align-center" @click="ChangeSeasons('Spring')">
                <Spring />
                <h2>Frühling</h2>
              </button>
            </template>
            <template v-if="(this.$store.state.area.season == Enums.Seasons.Summer.Value)">
              <button class="align-center" @click="ChangeSeasons('Winter')">
              <Summer/>
              <h2>Sommer</h2>
              </button>
            </template>
            <template v-if="(this.$store.state.area.season == Enums.Seasons.Autumn.Value)">
              <button class="align-center" @click="ChangeSeasons('Autumn')">
              <Autumn/>
              <h2>Herbst</h2>
              </button>
            </template>
            <template v-if="(this.$store.state.area.season == Enums.Seasons.Winter.Value)">
              <button class="align-center" @click="ChangeSeasons('Summer')">
                <Winter/>
                <h2>Winter</h2>
              </button>
            </template>
            <div class="time">
              {{Enums.Zeiten[this.$store.state.area.current.solarDaten[SolarIndex].Zeit]}}
            </div>
          </div>
                 
            <div class="card-small width-6 einstrahlung flex flex-column flex-align-center flex-center">
              <Einstrahlung />
              {{ (this.$store.state.area.current.solarDaten[SolarIndex].EnergieWechselrichter).toFixed(2) }}kWh
            </div>
            <!-- <div class="haustyp">
              {{this.$store.state.area.houseType}}
            </div> -->

            <!-- <div class="card-small akku flex flex-column flex-align-center flex-center">
              <h3 class="text-bold brand-color">{{Math.floor(this.$store.state.area.current.solarDaten[SolarIndex].Ladezustand * 100)}}% <Akku /></h3>
              <h3><span :class="'active-'+(this.$store.state.area.battery == 'Battery')">an</span> / <span :class="'active-'+(!this.$store.state.area.battery =='Battery' )">aus</span></h3>
              <div class="flex">
                
              </div>
            </div> -->

            <!-- <div class="poi flex">
              
              <Location />{{this.$store.state.content.currentPOI.Name}}
            </div> -->
          </div>
            
        
    
            
        </div>
        <div class="sun">
          <div class="sun-slider">
            <div class="bg-sun-range-slider" :style="GetRangeSliderBG"></div>
            <input class="range-slider" :value="this.$store.state.area.sunPosition * 100" type="range" @change="e=>this.changeTime(e.target.valueAsNumber)" @input="e=>this.changeTime(e.target.valueAsNumber)" />
          </div>
        </div>
      </div>
</template>

<script>


import Spring from "../SVG/zeiten_spring.svg";
import Summer from "../SVG/zeiten_summer.svg";
import Autumn from "../SVG/zeiten_autumn.svg";
import Winter from "../SVG/zeiten_winter.svg";
import Einstrahlung from "../SVG/einstrahlung.svg";
import Location from "../SVG/location.svg";
import Akku from "../SVG/akku.svg";
import Enums from '../Common/enums';

import sun_range_slider from '../Icons/sun_range_slider.png';
import sun_range_slider_winter from '../Icons/sun_range_slider_winter.png';

export default {
  name: "HeaderCard",
  components:{
    Spring,
    Summer,
    Autumn,
    Winter,
    Location,
    Akku,
    Enums,
    Einstrahlung
  },
  data(){
    return {
      Enums : Enums,
      sun_range_slider : sun_range_slider,
      value : this.$store.state.area.sunPosition * 100
    }
  },
  methods :{
    enableControls(){
      this.$store.state.world.mainScene.xr.Controls.enabled = true;
    },
    disableControls(){
      this.$store.state.world.mainScene.xr.Controls.enabled = false;
      
    },
    changeTime(value){
      this.value = value;
      //this.canvasController.SetTime(value * .01);
      this.$store.commit('area/SetSunPosition', value * .01);
    },
    ChangeSeasons(season){
      this.$store.commit("area/SetCurrentSeason", season);
    }
  },
  computed: {
    GetRangeSliderBG(){
      return {
        backgroundImage : "url("+ (this.$store.state.area.season === "Winter" ? sun_range_slider_winter : sun_range_slider) +")"
      }
    },
    SolarIndex: function () {

      var index = Math.floor((this.$store.state.area.sunPosition) * 6);
      index = index == 6 ? 5 : index;
    
      return index;
    },
  },
}
</script>

<style lang="scss">
.scene-status{
  pointer-events:all;
}

.einstrahlung svg {
  width: 50px;
}

.scene-status .card-small{
  margin-bottom:0;
}

.sun-slider{
  position: relative;
  padding: 0 1rem;
  padding-top: 34px;

  input{
    z-index: 1;
    position: relative;
  }
}

.bg-sun-range-slider{
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 50px;
  top: 0;
  background-size: 100%;
}

.season button{
  display:block;

  svg{
    margin: auto;
  }
}

</style>