import Utility from "../utils";
import axios from 'axios';
import LightFactory from '../Factory/LightFactory';
import mainConfig from "../../main.config";

export default {
  data(){
    settings : {}
  },
  methods: {
    UpdateLightSettings(newValue, oldValue){

      if(newValue.hasOwnProperty("SeasonLightSetting")){

        var settingsCopy = Object.assign({},this.settings);        
        var lightSettings = newValue.SeasonLightSetting;

        
        
        var settingsToChange = lightSettings.find(s => s.Season === this.store.state.area.season);
        
        if(settingsToChange == "undefined"){
          settingsToChange.DayLightSetting = LightFactory.GetLightSetting().DayLightSetting;
          settingsToChange.SunsetLightSetting = LightFactory.GetLightSetting().SunsetLightSetting;
          settingsToChange.NightLightSetting = LightFactory.GetLightSetting().NightLightSetting;
        }

        if(typeof(settingsToChange) != "undefined"){
       
          Object.keys(settingsToChange.DayLightSetting).map((key)=>{

            if(isNaN(parseFloat(settingsToChange.DayLightSetting[key]))){
              settingsCopy["day_" + key] = Utility.hexToRgbArray(settingsToChange.DayLightSetting[key]);
            }else{
              settingsCopy["day_" + key] = parseFloat(settingsToChange.DayLightSetting[key]);

            }

            //console.log("day_" + key ,typeof(settingsToChange.DayLightSetting[key]), parseFloat(settingsToChange.DayLightSetting[key]) ) ;
          });
          
          Object.keys(settingsToChange.SunsetLightSetting).map((key)=>{

            if(isNaN(parseFloat(settingsToChange.SunsetLightSetting[key]))){
              
              settingsCopy["sunset_" + key] = Utility.hexToRgbArray(settingsToChange.SunsetLightSetting[key]);
            }else{
              settingsCopy["sunset_" + key] = parseFloat(settingsToChange.SunsetLightSetting[key]);

            }

            //console.log("day_" + key ,typeof(settingsToChange.DayLightSetting[key]), parseFloat(settingsToChange.DayLightSetting[key]) ) ;
          });
          
          
          Object.keys(settingsToChange.NightLightSetting).map((key)=>{

            if(isNaN(parseFloat(settingsToChange.NightLightSetting[key]))){
              
              settingsCopy["night_" + key] = Utility.hexToRgbArray(settingsToChange.NightLightSetting[key]);
            }else{
              settingsCopy["night_" + key] = parseFloat(settingsToChange.NightLightSetting[key]);

            }

            //console.log("day_" + key ,typeof(settingsToChange.DayLightSetting[key]), parseFloat(settingsToChange.DayLightSetting[key]) ) ;
          });
          
          this.settings = settingsCopy;
          if(this.hasOwnProperty("updateDisplay")){
            this.updateDisplay();
          }
          
        }
      }
    },
    updateDisplay() {
      Object.keys(this.settings).map((settingsName)=>{
        Object.keys(this.gui.__folders).map((folderName)=>{
        this.gui.__folders[folderName].__controllers.map(controller => {
          if(controller.property == settingsName){
            controller.object[settingsName] = this.settings[settingsName];
            controller.updateDisplay()
          }
        });
      });
    });

      // Object.keys(this.gui.__folders).map((folderName)=>{
      //   this.gui.__folders[folderName].__controllers.map(controller => controller.updateDisplay());
      // });
     

    },
    SaveLightSettings(){

      var seasonCopy = [...this.$store.state.content.currentViewContent.SeasonLightSetting];
      var seasonSetting = seasonCopy.find(setting => setting.Season === this.$store.state.area.season );

      Object.keys(seasonSetting.DayLightSetting).map(key =>{
        if(Array.isArray(this.settings["day_" + key])){
          let arr = this.settings["day_" + key];
          seasonSetting.DayLightSetting[key] = Utility.RGBToHex({r : arr[0],g : arr[1],b : arr[2]})
        }else{
          seasonSetting.DayLightSetting[key] = parseFloat(this.settings["day_" + key]);
        }
      })
      Object.keys(seasonSetting.SunsetLightSetting).map(key =>{
        if(Array.isArray(this.settings["sunset_" + key])){
          let arr = this.settings["sunset_" + key];
          seasonSetting.SunsetLightSetting[key] = Utility.RGBToHex({r : arr[0],g : arr[1],b : arr[2]})
        }else{
          seasonSetting.SunsetLightSetting[key] = parseFloat(this.settings["sunset_" + key]);
        }
        
      })
      
      
      Object.keys(seasonSetting.NightLightSetting).map(key =>{
        if(Array.isArray(this.settings["night_" + key])){
          let arr = this.settings["night_" + key];
          seasonSetting.NightLightSetting[key] = Utility.RGBToHex({r : arr[0],g : arr[1],b : arr[2]})
        }else{
          seasonSetting.NightLightSetting[key] = parseFloat(this.settings["night_" + key]);
        }
        
      })

      /** Delete IDs in order to save settings */
      seasonCopy = seasonCopy.map((entry)=>{
        delete entry.id;

        delete entry.DayLightSetting.id;
        delete entry.SunsetLightSetting.id;
        delete entry.NightLightSetting.id;

        return entry;
      });

      
      if (this.$route.name != "Explorer") {
        var path = null;

        switch (this.$route.name) {
          case "Dashboard":
            path = "ui-section-dashboard";
            break;
          case "Configurator":
            path = "ui-section-config";
            break;
          case "ExplorerSingle":
            path = "ui-section-explorer";
            break;
          default:
            break;
        }
        
        axios({
          method: "put",
          url: mainConfig.CMS_BASE_URL + "/" + path,
          data: {
            SeasonLightSetting : seasonCopy
          },
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! UI Section Lightsettings wurden gesetzt: ", path);
          }
        }).catch(err => {
          console.log(err);
        });

      }else{

        const subTab = this.$store.state.content.currentSubTab;
        if (subTab == null) {
          return;
        }

        const id = this.$store.state.content.currentPOI.id;
        var putRequest = true;
        var subTabData = this.$store.state.content.currentPOI.SubTabs.map((subTab) => {

          //console.log(subTab);
          var obj = {
            id: subTab.id,
            __component: "sub-tab-component.sub-headline",
          };

          if (subTab.id === this.$store.state.content.currentSubTab.id) {

            if(this.$store.state.content.currentSubTab == null){
              putRequest = false;
            }
            //console.log("real SubTab");
            obj.SeasonLightSetting = this.$store.state.content.currentSubTab.SeasonLightSetting === null || typeof(this.$store.state.content.currentSubTab.SeasonLightSetting) == "undefined" ? seasonCopy : Object.assign(this.$store.state.content.currentSubTab.SeasonLightSetting , seasonCopy)
            //console.log(obj);
          }

          return obj;
        });

        axios({
          method:putRequest ? "put" : "post",
          url: mainConfig.CMS_BASE_URL + "/points/" + id,
          data: {
            SubTabs: subTabData,
          },
        }).then((response) => {
          if (response.status === 200) {
            alert("Yes! Subtab LightSetting wurde gesetzt");
          }
          //console.log(response);
        });

      }

    },
    ChangeLight(setting,time, value){
      var newPageObj = {};
      var lightSetting = {};

      Object.assign(newPageObj, this.$store.state.content.currentViewContent);

      
      if(newPageObj.SeasonLightSetting.length > 0){
        lightSetting = newPageObj.SeasonLightSetting.find(setting => setting.Season === this.$store.state.area.season);
      }else{
        lightSetting = LightFactory.GetLightSetting();
      }

      lightSetting[time][setting] = value;

      this.$store.commit("content/SetCurrentViewContent", newPageObj);


    },
  }
}