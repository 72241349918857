import AreaBase from "./AreaBase";
import {Area, Battery, Car, Fascade, OperatorConcept, Pump, Room} from "../Enum/Enums"

class ApartmentBuildingArea extends AreaBase {
  constructor(props: any) {
    super(props);
    this.name = Area.ApartmentBuilding;
    this.store = props;
    this.routeParam = "apartmentbuilding"
    this.configSettings = Object.assign(this.configSettings, { 
      operatorConcept : true,
      fascade: true,
      houseType: false,
      pv: false,
      shadow: false,
      car: true, 
      pump: true, 
      greenRoof: true,
      battery:true,
    })

    // Noch keine Modelle vorhanden
    // TODO: HInzufügen der Räume sobald Modelle dafür vorliegen
    // Room.Livingroom, Room.Hallway
    this.rooms.push(Room.ApartmentBuilding, Room.UndergroundGarage,Room.Balcony, Room.InstallationsRoom, Room.Livingroom, Room.Hallway);
    this.defaultRoom = Room.ApartmentBuilding;
  
    this.cmsID = 3;

    this.dataPath = '/data/solardaten_apartmentBuilding.json'

  }

  SetSolarData(dataset: any): void {
    var newDataName = `apartmentbuilding__battery_${(this.store.state.area.battery == Battery.Battery)}__car_${(this.store.state.area.car == Car.Car)}__pump_${(this.store.state.area.pump == Pump.Pump)}__facade__${(this.store.state.area.fascade == Fascade.Fascade)}__operator_${(this.store.state.area.operatorConcept == OperatorConcept.OnlyOperatorCurrent ? "OCC" : "OC")}__participation_${(this.store.state.area.participationRate * 100)}__Season_${this.store.state.area.season}`;

    console.log("newDataName" , newDataName);

    this.solarDaten = dataset[newDataName];
  }
}

export default ApartmentBuildingArea;