<template>
  <transition name="fade">
    <div class="loader">
      <div class="load-container">
        <div class="load-container-inner">
          <LoadPanel :class="'is-loading-'+ IsLoading"/>

          <transition name="fade">
            <template v-if="IsLoading">
              <div class="">
                <h1>
                  Interaktive Ausstellung wird geladen
                  <span class="loading-dots">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </h1>

                <div class="progress-indicator">
                  <div class="progress-bar">
                    <div
                      class="progress-inner"
                      :style="{ width: GetLoadProgress * 100 + '%' }"
                    ></div>
                  </div>
                  {{(GetLoadProgress * 100).toFixed(0) + "%"}}
                </div>
              </div>
            </template>

            <template v-if="!IsLoading">
              <div class="">
                <h1>Ausstellung ist bereit</h1>
                <button
                  v-if="this.showBtn"
                  class="cta-button-small width-12 always-clickable"
                  @click="Enter"
                >
                  Ausstellung betreten
                </button>
              </div>
            </template>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadPanel from "../SVG/LoadPanel.svg";
import LoadMixin from "../Mixins/LoadMixin";

export default {
  name: "Loader",
  components: {
    LoadPanel,
  },
  mixins: [LoadMixin],
  computed: {
    GetLoadProgress: function() {
      const progressObject = this.$store.state.world.roomProgress;
      const loadObjectNames = this.$route.query.areasToLoad;

      if(typeof(loadObjectNames) == "undefined"){return 0;}
      const loadObjectCount = loadObjectNames.length;

      const progress = loadObjectNames
        .map((objName) => {
          return progressObject[objName].progress;
        })
        .reduce((a, b) => a + b);

      this.CheckLoading(progress / loadObjectCount);

      return (progress / loadObjectCount);
    },
    IsLoading: function() {
      return !this.showBtn;
    },
  },
  //store,
  data() {
    return {
      nextParams: null,
      nextPath: "",
      showBtn: false,
    };
  },
  props:{
    routerQuery : null
  },
  beforeMount() {
    
   

  },
  mounted() {
     console.log("beforeMount" , this.$route, this.$route.query, this.$props.routerQuery);

    var areasToLoad = this.$route.query.areasToLoad
     areasToLoad = typeof(areasToLoad) == "undefined" ? this.$props.routerQuery.areasToLoad : areasToLoad;
    
    var nextPath = this.$route.query.nextPath;
    nextPath = typeof(nextPath) == "undefined" ? this.$props.routerQuery.nextPath : nextPath;

    this.$store.dispatch("world/LoadRooms", areasToLoad).finally(()=>{
      console.log("Loadded complete");
    }).catch((error)=>{
      console.log("error" , error);
    });

    this.nextPath = nextPath;
    this.nextParams = this.$route.query.nextParams;
  },
  methods: {
    CheckLoading(progress){

      if(progress == 1){
        this.showBtn = true;        
      }
    },
    Enter() {
      this.$store.state.audio.controller.EnableSounds();

      console.log("nextParams" , this.nextParams, this.$route.query.nextParams);
      if(typeof(this.nextParams) != "undefined"){


        let poi = this.$store.state.content.poi.find( poi=> poi.id == parseInt(this.nextParams));
        if(poi){
          
          //this.$store.dispatch("content/SetSubTab",poi.SubTabs[0]);
          console.log("poi.SubTabs" , poi.SubTabs[0]);

          // this.$store.state.world.mainScene.xr.Controls.SetPosition(poi.SubTabs[0].subtabPosition.x,poi.SubTabs[0].subtabPosition.y,poi.SubTabs[0].subtabPosition.z);
          // this.$store.state.world.mainScene.xr.Controls.SetTarget(poi.SubTabs[0].subtabTarget.x,poi.SubTabs[0].subtabTarget.y,poi.SubTabs[0].subtabTarget.z);
        
        }
      }

      this.$router.push(this.nextPath);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
body.clickDisabled .always-clickable {
  pointer-events: all !important;
}
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990;

  svg {
    width: 200px;
    margin: auto;
    margin-bottom: 1rem;
  }
}

.progress-indicator{
  text-align: center;
}
.progress-bar {
  background: #fff;
}
.progress-inner {
  height: 5px;
  background: $orange;
  margin-bottom: 1rem;
}
.loading-dots {
  span {
    opacity: 1;
    animation: dotOpacityAnimation 2s infinite;
  }

  @for $i from 0 through 3 {
    span:nth-child(#{$i}) {
      animation-delay: 0.5s * $i;
    }
  }
}

@keyframes dotOpacityAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

$duration: 5s;

.panel {
  fill: #f9c697;
  animation-timing-function: linear;
  animation: loader $duration infinite;
}

.is-loading-false .panel{
  animation: none;
  fill: #f38d2f;
}

@for $i from 0 through 12 {
  .panel-#{$i} {
    animation-delay: $duration - ($duration / 12) * $i;
  }
}

@keyframes loader {
  0%,
  100% {
    fill: #f9c697;
  }
  75% {
    fill: #f38d2f;
  }
}

.loading-info {
  position: absolute;
  width: 100%;
  bottom: 1rem;
  left: 0;
  padding: 1rem;
  right: 0;
  width: 80%;
  margin: auto;
  background: #fff;
  font-size: 80%;
  border-radius: 20px;
}

.load-container-indicator {
  height: 2px;
  background: #f38d2f;
}
</style>
