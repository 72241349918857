import AreaBase from "./AreaBase";
import {Area, Room} from "../Enum/Enums";

class FamilyHouseArea extends AreaBase {
  constructor(props: any) {
    super(props);
    this.name = Area.FamilyHouse;
    this.routeParam = "familyhouse"
    this.configSettings = Object.assign(this.configSettings, {
      houseType: true,
      pv: true,
      car: true,
      shadow: true,
      pump: true,
      battery:true
    })
    this.dashboardSettings =  Object.assign(this.dashboardSettings,{subBilanz: false})
    this.rooms.push(Room.House,Room.Kitchen, Room.Basement);
    this.defaultRoom = Room.House;

    this.cmsID = 2;

    this.dataPath = '/data/solardaten_familyHouse.json';

  }
}

export default FamilyHouseArea;