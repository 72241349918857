<template>
  <div class="partialloader" v-if="$store.state.world.partialLoading || mustEnterManually">
    Loading
  </div>
</template>

<script>
export default {
  data(){
    return {
      mustEnterManually : false
    }
  },
  watch:{
    "$store.state.world.partialLoading" : function(value){
      console.log("partialLoading" , value);

      if(value){
        this.mustEnterManually = true;
      }
    }
  },
  setup() {
    
  },
}
</script>

<style scoped>
.partialloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ff6347;
  z-index: 999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>