<template>
  <h1>
    InlineLoader
  </h1>
</template>

<script>
export default {
  name: "InlineLoader",
  beforeCreate() {
    //console.log("InlineLoader beforeCreate");
  },
  setup() {},
};
</script>
