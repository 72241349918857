<template>
  <div class="main-menu" v-if="CheckVisibility">
    <nav>
      <RouterLink
        @click.native="Clear"
        class="main-menu-link"
        :class="{ active: IsActive('TinyCity') }"
        to="/desktop/tinycity"
        ><TinyCityIcon :active="IsActive('TinyCity')"/>Solar City</RouterLink
      >
      <RouterLink
        @click.native="Clear"
        v-if="$store.state.area.current.name != 'Apartment'"
        class="main-menu-link"
        :class="{ active: IsActive('Dashboard') }"
        :to="GetLink('dashboard')"
        ><DashboardIcon :active="IsActive('Dashboard')"/>Dashboard</RouterLink
      >
      <RouterLink
        @click.native="Clear"
        class="main-menu-link"
        :class="{ active: IsActive('Explorer') }"
        :to="GetLink('explore')"
        ><ExplorerIcon :active="IsActive('Explorer')"/>Explorer</RouterLink
      >
      <!-- <MenuSVG /> -->

      <!-- <router-link to="/desktop/configurator">Settings</router-link>
      <router-link to="/desktop/dashboard">Dashboard</router-link>
      <router-link :to="GetExplorerUrl()">Explore</router-link> -->
    </nav>
  </div>
</template>

<script>
import MenuSVG from "../SVG/Mainmenu/MainMenuIcon.vue";

import TinyCityIcon from "../SVG/Mainmenu/TinyCityIcon.vue";
import ConfigIcon from "../SVG/Mainmenu/ConfigIcon.svg";
import DashboardIcon from "../SVG/Mainmenu/DashboardIcon.vue";
import ExplorerIcon from "../SVG/Mainmenu/ExplorerIcon.vue";

import Footer from "../UI_Sections/Footer/Footer.vue";
import RouterLinkComponent from "./RouterLinkComponent.vue";

export default {
  name: "MainNavigation",
  components: {
    MenuSVG,
    Footer,
    TinyCityIcon,
    ConfigIcon,
    DashboardIcon,
    ExplorerIcon,
  },
  computed: {
    CheckVisibility: function() {
      const pageWithout = ["Home", "TinyCity", "Loader"];

      // ToDo: Check ob Modelle geladen sind falls Configurator nicht inline angezeigt wird
      return (
        !pageWithout.includes(this.$route.name) &&
        this.$store.state.area.current != null
      );
    },
  },
  methods: {
    IsActive: function(name) {
      if (this.$route.name == name) {
        return true;
      } else if (this.$route.name == "ExplorerSingle" && name == "Explorer") {
        return true;
      } else {
        return false;
      }
    },
    GetLink(routeName) {
      var url =
        "/desktop/" + this.$router.currentRoute.params.area + "/" + routeName;
      return url;
    },
    Clear() {
      this.$store.dispatch("content/SetSubTab", null);
      this.$store.dispatch("content/SetCurrentPOI", null);
    },
    RouterLinkComponent() {
      var url =
        "/desktop/" + this.$router.currentRoute.params.area + "/explore";
      if (this.$store.state.content.currentPOI != null) {
        url += "/" + this.$store.state.content.currentPOI.id;
      }
      return url;
    },
  },
};
</script>

<style scope lang="scss">
.main-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 980;

  nav {
    padding: 0;
    width: 170px;
    padding-left: 1rem;
  }
}

.main-menu-link {
  padding: 1rem;
  width: 100%;
  display: block;
  border-radius: 15px;
  background: #f8f8f8;
  margin-bottom: 1rem;
  color: $black;
  padding-left: 45px;
  position: relative;

  svg {
    position: absolute;
    left: 10px;
    width: 30px;
    height: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.active {
    background: #373a48;
    color: #fff;

  }
}

.configurator-overlay .main-menu {
  display: none;
}
</style>
