<template>
  <div class="cookies">
    <p>
      Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren
      Rechten als Benutzer finden Sie in unserer Datenschutzerklärung im
      Impressum. Klicken Sie auf „OK“, um Cookies zu akzeptieren und direkt
      unsere Website besuchen zu können.
    </p>
    <button @click="AcceptCookies">OK</button>
  </div>
</template>
<script>
export default {
  name: "Cookies",
  components: {},
  mounted() {
    //console.log(this.$route);
  },
  methods: {
      AcceptCookies(){
          this.$store.commit("AcceptCookies",true)
      }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
.cookies {
  position: fixed;
  width:60%;
  height: auto;
  bottom: 0;
  padding: 1rem;
  z-index: 99;
  left: 15%;
  pointer-events: all;
  background-color: #3f4355;
  p {
    color: white;
    margin-bottom: 0;
  }
  border-radius: $border-radius-3 $border-radius-3 0 0 ;
  //text-align: center;
display: flex;
  align-items: center;
  justify-content: center;
}


button {
  background-color: #f38c2f;
  padding: 1rem;
  width: 100px;
  height: 50px;
  color: white;
border-radius: $border-radius-3;
margin-left: 1rem;
}
</style>