<template>
  <div>
    <router-link class="" :to="this.$props.to">
      <slot/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "RouterLink",
  props: ["to"],
  methods: {
  },
};
</script>