<template>
  <header>
    <div class="flex wrapper-inner flex-between">
    <div class="flex flex-align-center">
      <div class="logo">
        <RouterLink to="/gallery">
          <SolarwendeLogo />
        </RouterLink>
      </div>

      <Debug />


      <!-- <div class="supporter-logos">
        <BerlinLogo />
        <DGSLogo />
      </div> -->
    </div>

    
    <!-- <HeaderCard v-if="this.$route.name != 'Home' && this.$route.name != 'Gallery' && this.$store.state.world.sceneReady"/> -->
    </div>
  </header>
</template>
<script>
import SolarwendeLogo from '../SVG/solarwende_logo.svg';
import BerlinLogo from '../SVG/berlin_logo.svg';
import DGSLogo from '../SVG/dgs_logo.svg';
import HeaderCard from './HeaderCard';

import Debug from '../Debug/Debug';


export default {
  name :"Header",
  components : {
    SolarwendeLogo,
    BerlinLogo,
    DGSLogo,
    HeaderCard,
    Debug
  },
  mounted(){
    //console.log(this.$route);
  },
  methods :{
    
  },

}
</script>

<style lang="scss">

header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index:991;
  pointer-events: none;

  h2{
    margin-bottom: 0;
  }
}

.logo {
  width: 150px;
  pointer-events: all;
}

.scene-status {
  align-self: center;
  justify-self: flex-end;
  background: #fff;
  padding: 1rem;
  min-width: 330px;
  border-radius: 20px;
}


.supporter-logos{
  display: flex;
  justify-content: center;
  align-items: flex-end;

  svg{
    margin: 0 1rem;
    max-height: 100px;
    width: auto;
    height: 70px;
    vertical-align: middle;
    align-self: center;
    
  }

  svg * {
    //fill: #aaa;
  }

}

h3{
  color:#aaa;
}
.season{

  svg{
    height: 30px;
    width: 30px;
  }
}

.akku {
  padding: 0 2rem;

  .active-false{
    color:#aaa;
  }
}

.akku svg {
  width: 30px;
  height: 20px;
  margin: auto;
  *{
    fill: #F38C2F;
  }
}
</style>